<template>
  <div class="page-content">
    <div class="content-box" v-loading="loading">
      <section-title title="实习评价" />
      <div>
        <el-row class="u-flex">
          <div class="mr-8">学生姓名：</div>
          <div>{{ internshipInfo.StudentName }}</div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">实习时间：</div>
          <div v-if="internshipInfo.StartTime">
            {{ formartDate(internshipInfo.StartTime, "day") }} 至
            {{ formartDate(internshipInfo.EndTime, "day") }}
          </div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">实习岗位：</div>
          <div>前端开发工程师</div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">实习状态：</div>
          <div>{{ internshipInfo.ImplementStatus_V }}</div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">学生打分：</div>
          <el-rate :value="internshipInfo.Evaluate" disabled> </el-rate>
        </el-row>
        <el-row>
          <div class="mr-8">实习评价：</div>
          <el-input
            v-model="internshipInfo.Content"
            type="textarea"
            resize="none"
            rows="5"
            class="u-flex-1"
          ></el-input>
        </el-row>
      </div>
      <div class="form-footer">
        <!-- <el-button type="primary" @click="handleSave">保存</el-button> -->
        <el-button type="default" @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo } from "@/api/internshipEvaluate";
import SectionTitle from "@/components/SectionTitle.vue";
import contentTools from "@/mixins/content-tools";
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    }
  },
  components: {
    SectionTitle
  },
  mixins: [contentTools],
  data() {
    return {
      loading: false,
      internshipInfo: ""
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        await this.getInternshipInfo();
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async getInternshipInfo() {
      const res = await getInfo(this.id);
      if (res.IsSuccess) {
        this.internshipInfo = res.Result;
      }
    },
    handleSave() {}
  }
};
</script>

<style lang="scss" scoped>
.content-box {
  width: 850px;
  margin: 0 auto;
  .el-row {
    display: flex;
    margin-bottom: 16px;
  }
}
</style>
